import React, { useState, useEffect, useCallback, useRef } from "react";
import { getFirestore, collection, getDocs, query, limit, startAfter } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
import '../firebase';

const PAGE_SIZE = 6;

const CarListing = () => {
  const [cars, setCars] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const isFetching = useRef(false); // Create a ref to track fetching state

  const fetchDataFromFirestore = async (startAfterDoc = null) => {
    if (isFetching.current) return; // Prevent fetching if already in progress
    isFetching.current = true; // Set fetching state

    const db = getFirestore();
    const storage = getStorage();
    let carsQuery = query(collection(db, "cars"), limit(PAGE_SIZE));

    if (startAfterDoc) {
      carsQuery = query(carsQuery, startAfter(startAfterDoc));
    }

    const querySnapshot = await getDocs(carsQuery);
    const temporaryArr = [];

    for (const doc of querySnapshot.docs) {
      const carData = doc.data();
      const images = [];

      const imagePaths = carData.CompressedImagePaths && carData.CompressedImagePaths.length > 0 
        ? carData.CompressedImagePaths 
        : carData.ImagePaths;

      for (const imagePath of imagePaths) {
        const imageRef = ref(storage, imagePath);
        const imageUrl = await getDownloadURL(imageRef);
        images.push(imageUrl);
      }

      temporaryArr.push({ id: doc.id, ...carData, images });
    }

    setCars((prevCars) => [...prevCars, ...temporaryArr]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
    isFetching.current = false; // Reset fetching state
  };

  useEffect(() => {
    fetchDataFromFirestore(); // Initial fetch
  }, []);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.offsetHeight) {
      if (!loading && lastVisible) {
        fetchDataFromFirestore(lastVisible);
      }
    }
  }, [loading, lastVisible]);
  
  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);


  if (!cars) return <div>Se incarca...</div>;

  return (
    <Helmet title="Masini">
      <CommonSection title="Masini disponibile" />

      <section>
        <Container>
          <Row>
            {cars.map((item) => (
              <CarItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarListing;
