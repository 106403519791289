import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import AutomatRulate from "../../assets/all-images/LogoAutomatRulate.png";
import "../../styles/footer.css";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                <img src={AutomatRulate} alt="Automat Rulate" className="logo-image" />
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content">
            ◽Vanzari auto
              <br></br>
            ◽Firma autorizată în comerțul cu autoturisme
              <br></br>
            ◽Posibilitate finanțare în rate fixe
            </p>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Biroul nostru</h5>
              <p className="office__info">Adresa: Arad, Buteni, Strada Orizontului, nr.55</p>
              <p className="office__info">Phone: 0741.383.836</p>

              <p className="office__info">Email: matsellauto@gmail.com</p>
            </div>
          </Col>

        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
