import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../../styles/whatsapp-icon.css'; // Import your CSS file

const WhatsAppIcon = () => {
    return (
      <a href="tel:+0741383836" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon"  />
      </a>
    );
  };

export default WhatsAppIcon;